import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Content from 'components/new/content'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Spacer from 'components/new/spacer'
import Button from 'components/button/button'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import LocationsMapSearch from 'components/locations-map/locations-map-search'

const AgMaintenanceAgreementsPage = props => {
  const {
    data: {
      allSanityLocation: { nodes: allSanityLocationNodes },
      heroImage,
    },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>Ag Equipment Service Plans | Hutson Inc</title>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Ag Equipment Service Plans'
        overlayOpacity={0.3}
      />
      <Content kind='full'>
        <H2>Lock in today's labor rate and save on future maintenance costs</H2>
        <P>
          As a John Deere equipment owner, you work hard to keep your operation running smoothly.
          But unexpected breakdowns and high maintenance costs can disrupt your productivity and
          cause stress. You deserve reliable equipment that works as hard as you do.
        </P>
        <P>
          At Hutson, we understand your challenges and have the expertise to help. With decades of
          experience anda reputation for reliability, we are your trusted partner in maintaining
          your equipment.
        </P>
        <P>
          Our new service plans offer you new ways to plan ahead for your business. You get to
          choose the length of your terms and level of support your operation requires.
        </P>
      </Content>
      <LightSection>
        <Content kind='full'>
          <H2 id='parts-warranty'>Service Plans</H2>
          <Spacer size='m' />
          <Grid>
            <Column>
              <H3>Sprayers</H3>
              {/* <P>...</P> */}
              <Spacer size='s' />
              <ButtonContainer>
                <Button
                  as={Link}
                  to='/service/ag-maintenance-agreements/sprayers/'
                  ghost
                  color='green'
                >
                  View service plans
                </Button>
              </ButtonContainer>
            </Column>
            <Column>
              <H3>Combines</H3>
              {/* <P>...</P> */}
              <Spacer size='s' />
              <ButtonContainer>
                <Button
                  as={Link}
                  to='/service/ag-maintenance-agreements/combines/'
                  ghost
                  color='green'
                >
                  View service plans
                </Button>
              </ButtonContainer>
            </Column>
            <Column>
              <H3>Forage Harvesters</H3>
              {/* <P>...</P> */}
              <Spacer size='s' />
              <ButtonContainer>
                <Button
                  as={Link}
                  to='/service/ag-maintenance-agreements/self-propelled-forage-harvesters/'
                  ghost
                  color='green'
                >
                  View service plans
                </Button>
              </ButtonContainer>
            </Column>
          </Grid>
        </Content>
      </LightSection>
      <Content kind='full'>
        <H2>Contact your nearest service shop to learn more</H2>
      </Content>
      <LocationsMapSearch locations={allSanityLocationNodes} />
    </Layout>
  )
}

const LightSection = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const Grid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
  }
`

const Column = styled.div`
  background-color: #fff;
  padding: 24px;

  :not(:last-child) {
    margin-bottom: ${props => props.theme.size.m};
  }

  @media (min-width: 900px) {
    ${column('1/3', 24)}
  }
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

export const pageQuery = graphql`
  query agMaintenanceAgreementsPageQuery {
    heroImage: file(relativePath: { eq: "service/maintenance-agreements/sprayer-sma-hero.jpg" }) {
      ...FullWidthImage
    }
    allSanityLocation(
      filter: { retailLocation: { eq: true } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        address
        city
        directionsLink
        geolocation {
          lat
          lng
        }
        hours {
          sunday
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
        }
        locationNumber
        phoneNumber
        slug
        specialHours {
          date
          hours
          title
        }
        state
        status
        title
        zip
      }
    }
  }
`

export default AgMaintenanceAgreementsPage
